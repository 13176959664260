import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component {
	static propTypes = {
		imagePath: PropTypes.string,
		display: PropTypes.bool,
		closeCallBack: PropTypes.func,
	};

	static defaultProps = {
		imagePath: '',
		display: false,
		closeCallBack: null,
	};

	render() {
		const alText = this.props.imagePath.slice(this.props.imagePath.lastIndexOf('/') + 1);

		return (
			<div className="modal" style={{ display: this.props.display ? 'block' : 'none' }}>
				<div className="modalHeader">
					<button className="modalCloseBtn" onClick={this.props.closeCallBack}>
						<div className="modalX">
							<i className="fa fa-times-circle fa-lg" />
						</div>
					</button>
				</div>
				<div className="modalImageContainer">
					<img className="modalImage" alt={alText} id="modalImage" src={this.props.imagePath} />
				</div>
				<div className="modalClick" onClick={this.props.closeCallBack} />
			</div>
		);
	}
}

export default Modal;
