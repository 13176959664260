import React from 'react';
import Data from '../data/resume.json';
import BluePanel from '../components/bluePanel';
import Technologies from './technologies';
import Experience from './experience';
import Education from './education';
import Achievements from './achievements';
import ScrollStyle from '../components/scrollStyle';
import '../pages.scss';

class Resume extends React.Component {
	state = {
		categories: [],
		categoryShown: null,
		categoryTypes: {
			Technologies: 'Technologies',
			Experience: 'Experience',
			Education: 'Education',
			Achievements: 'Achievements',
		},
		top: '-320px',
	};

	componentDidMount() {
		let arr = [];
		for (let property in Data) {
			if (Data.hasOwnProperty(property)) {
				arr.push({ section: property, details: Data[property] });
			}
		}
		// this.setState({categories: arr, categoryShown: arr[0].section});
		setTimeout(() => {
			this.setState({ top: 20, categories: arr, categoryShown: arr[0].section });
		}, 500);
	}

	sectionClick(object) {
		this.setState({ categoryShown: object.section });
	}

	getResumeCategories() {
		if (this.state.categories) {
			return (
				<div>
					{this.state.categories.map((data, index) => {
						return (
							<p
								onClick={() => {
									this.sectionClick(data);
								}}
								className={data.section === this.state.categoryShown ? 'selectedSection' : 'sections'}
								key={index}
							>
								{data.section}
							</p>
						);
					})}
				</div>
			);
		} else {
			return <div />;
		}
	}

	getCategoryDetails() {
		let returnedHTML = null;

		switch (this.state.categoryShown) {
			case this.state.categoryTypes.Technologies:
				returnedHTML = <Technologies categories={this.state.categories} categoryShown={this.state.categoryShown} />;
				break;
			case this.state.categoryTypes.Experience:
				returnedHTML = <Experience categories={this.state.categories} categoryShown={this.state.categoryShown} />;
				break;
			case this.state.categoryTypes.Education:
				returnedHTML = <Education categories={this.state.categories} categoryShown={this.state.categoryShown} />;
				break;
			case this.state.categoryTypes.Achievements:
				returnedHTML = <Achievements categories={this.state.categories} categoryShown={this.state.categoryShown} />;
				break;
			default:
				returnedHTML = <Technologies categories={this.state.categories} categoryShown={this.state.categoryShown} />;
				break;
		}
		return returnedHTML;
	}

	render() {
		return (
			<div style={{ height: 'calc(100vh - 96px)' }}>
				<div ref="content" style={{ position: 'relative', top: this.state.top, transition: 'top 1.25s' }}>
					<BluePanel>
						<div className="gridColumns">
							<div className="bluePanelScroll" style={Object.assign({}, ScrollStyle, { maxHeight: 'calc(100vh - 200px', width: '95vw' })}>
								{this.state.categoryShown ? this.getCategoryDetails() : ''}
							</div>
							<div style={{ borderLeft: '1px solid rgb(39, 186, 233)', paddingLeft: '10px', minWidth: '130px' }}>
								{this.getResumeCategories()}
							</div>
						</div>
					</BluePanel>
				</div>
			</div>
		);
	}
}

export default Resume;
