import React from 'react';
import PropTypes from 'prop-types';

class Achievements extends React.Component {
	static propTypes = {
		categories: PropTypes.array,
		categoryShown: PropTypes.string
	};

	findInCategories(){
		for(let i = 0; i < this.props.categories.length; i++){
			if(this.props.categories[i].section === this.props.categoryShown){
				return this.props.categories[i];
			}
		}
	}

	render() {
		let categoryDetails = this.findInCategories();

		let detailKeys = Object.keys(categoryDetails.details);

		return (
			<div style={{letterSpacing:'1px'}}>
				{
					detailKeys.map((key) => {
						return(
							<div key={key} style={{display:'flex', flexWrap:'wrap', marginBottom:'15px'}}>
								<div style={{flex:'1 1'}}> {categoryDetails.details[key]}</div>
							</div>
						)
					})
				}
			</div>
		)
	}
}

export default Achievements;