import React from 'react';
import Data from '../data/portfolio.json';
import BluePanel from '../components/bluePanel';
import ScrollStyle from '../components/scrollStyle';
import Modal from './modal';
import '../pages.scss';
import './portfolio.scss';

class Portfolio extends React.Component {
	constructor() {
		super();

		this.state = {
			portPieceShown: null,
			activeImageIndex: 0,
			displayModal: false,
			left: '-100vw',
			currentPiece: { paths: [] },
		};

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	componentDidMount() {
		// this.setState({portPieceShown: Data.Portfolio[0].ID});
		const currentPiece = this.findPiece(Data.Portfolio[0].ID);
		setTimeout(() => {
			this.setState({ left: 0, portPieceShown: Data.Portfolio[0].ID, currentPiece: currentPiece });
		}, 500);
	}

	sectionClick(object) {
		let currentPiece = null;
		for (let i = 0; i < Data.Portfolio.length; i++) {
			if (Data.Portfolio[i].ID === object.ID) {
				currentPiece = Data.Portfolio[i];
			}
		}

		this.setState({ portPieceShown: object.ID, activeImageIndex: 0, currentPiece: currentPiece });
	}

	getPieceNames() {
		return (
			<div>
				{Data.Portfolio.map((data, index) => {
					return (
						<p
							key={index}
							onClick={() => {
								this.sectionClick(data);
							}}
							className={data.ID === this.state.portPieceShown ? 'selectedSection' : 'sections'}
						>
							{data.title}
						</p>
					);
				})}
			</div>
		);
	}

	openModal() {
		this.setState({ displayModal: true });
	}

	closeModal() {
		this.setState({ displayModal: false });
	}

	findPiece(portPieceShown = this.state.portPieceShown) {
		for (let i = 0; i < Data.Portfolio.length; i++) {
			if (Data.Portfolio[i].ID === portPieceShown) {
				return Data.Portfolio[i];
			}
		}
	}

	getPortfolioDetails() {
		const currentPiece = this.findPiece();

		const showSmallPictures = currentPiece.paths.length > 1;
		const altText = currentPiece.paths[this.state.activeImageIndex].slice(currentPiece.paths[this.state.activeImageIndex].indexOf('/') + 1);

		return (
			<div style={{ display: showSmallPictures ? 'flex' : 'relative', height: '100%' }}>
				<table style={{ width: '100%', height: '100%' }}>
					<tbody>
						<tr style={{ height: '85%' }}>
							<td style={{ verticalAlign: 'top' }}>
								<div style={{ display: 'flex', flexWrap: 'wrap', height: '100%', width: '100%' }}>
									<div id="portInformation" style={{ flex: '.75 1', maxWidth: '350px' }}>
										<div>
											<h3 className="subHeader">CLIENT</h3>
											<p className="content">{currentPiece.client}</p>
										</div>
										<div>
											<h3 className="subHeaderExtend">TECHNOLOGY</h3>
											<p className="content">{currentPiece.technology}</p>
										</div>
										<div style={{ maxWidth: '850px', paddingRight: '20px', marginBottom: '10px' }}>
											<h3 className="subHeaderExtend">DESCRIPTION</h3>
											<p className="content">{currentPiece.description}</p>
										</div>
									</div>
									<div id="portPicture1" style={{ flex: '1 1', textAlign: 'center' }}>
										<img
											alt={altText}
											onClick={this.openModal}
											style={{ boxShadow: 'rgb(0, 0, 0) 2px 2px 5px', cursor: 'pointer', width: '75%' }}
											src={window.location.origin + '/' + currentPiece.paths[this.state.activeImageIndex]}
										/>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div
									style={{
										display: showSmallPictures ? 'flex' : 'none',
										flexWrap: 'wrap',
										width: '100%',
										marginTop: '10px',
										borderTop: currentPiece.paths.length > 1 ? '1px solid rgb(39, 186, 233)' : 'none',
										paddingTop: '10px',
									}}
								>
									{currentPiece.paths.map((data, index) => {
										if (currentPiece.paths.length > 1) {
											const subAltText = currentPiece.paths[index].slice(currentPiece.paths[index].lastIndexOf('/') + 1);
											return (
												<div
													key={index}
													onClick={() => {
														this.setState({ activeImageIndex: index });
													}}
													className={index === this.state.activeImageIndex ? 'subImageSelected' : 'subImage'}
												>
													<img
														alt={subAltText}
														style={{ boxShadow: 'rgb(0, 0, 0) 2px 2px 5px', width: '15vw', minWidth: '50px', maxWidth: '175px' }}
														src={window.location.origin + '/' + currentPiece.paths[index]}
													/>
												</div>
											);
										}
										return <div key={index} />;
									})}
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}

	getModal() {
		return (
			<Modal
				display={this.state.displayModal}
				closeCallBack={this.closeModal}
				imagePath={window.location.origin + '/' + this.statecurrentPiece.paths[this.state.activeImageIndex]}
			/>
		);
	}

	render() {
		console.log('currentPiece = ', this.state.currentPiece);
		return (
			<div style={{ height: 'calc(100vh - 96px)' }}>
				<div ref="content" style={{ position: 'relative', top: '3%', height: '100%', left: this.state.left, transition: 'left 1.5s' }}>
					<BluePanel>
						<Modal
							display={this.state.displayModal}
							closeCallBack={this.closeModal}
							imagePath={window.location.origin + '/' + this.state.currentPiece.paths[this.state.activeImageIndex]}
						/>
						<div className="gridColumns">
							<div className="bluePanelScroll" style={Object.assign({}, ScrollStyle, { width: '100%', height: '100%' })}>
								{this.state.portPieceShown ? this.getPortfolioDetails() : ''}
							</div>
							<div
								className="bluePanelScroll"
								style={Object.assign({}, ScrollStyle, {
									borderLeft: '1px solid rgb(39, 186, 233)',
									paddingLeft: '10px',
									width: '225px',
									minWidth: '162px',
									height: '100%',
								})}
							>
								{this.getPieceNames()}
							</div>
						</div>
					</BluePanel>
				</div>
			</div>
		);
	}
}

export default Portfolio;
