import React from 'react';
import PropTypes from 'prop-types';

const PageNotFound = (props) => {

    const style404 = {
        fontSize: '1.5em',
        fontWeight: 'bold'
    };

    let message = 'Page not found';
    if(props.text)
        message = props.text;
    return (
        <div style={{textAlign:'center', color:"white", background:"rgba(0,0,0,1)", width:"100vw", height:"100vh"}}>
			<div style={style404}>404</div>
            <h1 style={{margin: 0}}>{message}</h1>
            <p>I know I said I have a vast imagination, but I never imagined this url.</p>
            <p>Are you 100% sure you have the right address?</p>
            <p style={{color:"#30cee9", fontSize:"1.1em"}}>Click on my {<span style={{color:"#e48122"}}>logo</span>} above to return to the home page.</p>

        </div>
    );
};

PageNotFound.propTypes = {
    text: PropTypes.string
};

export default PageNotFound;