import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const HeaderButton = ({ maxw, minw, title, id, link }) => {
	const maxWidth = maxw ? maxw : '370';
	const minWidth = minw ? minw : '200';

	if (link) {
		return (
			<div className="headerButtonContainer" style={{ maxWidth: `${maxWidth}px`, minWidth: `${minWidth}px` }}>
				<NavLink id={id} className="navLink" activeClassName="navLinkActive" to={link} exact>
					<span>{title}</span>
				</NavLink>
			</div>
		);
	} else {
		return null;
	}
};

export default HeaderButton;
