import React, { useRef } from 'react';
import Space from './space/space.js';
import Foreground from './images/forground6.png';
import './background.css';

const Background = () => {
	const cityRef = useRef(null);

	return (
		<div id="background">
			<Space city={cityRef} />
			<div ref={cityRef} id="cityContainer">
				<img id="city" src={Foreground} alt="Foreground" />
				<div id="towerFlash" />
				<div id="signFlash" />
			</div>
		</div>
	);
};

export default Background;
