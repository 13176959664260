import React from 'react';
import BluePanel from './components/bluePanel';
import ScrollStyle from './components/scrollStyle';
import './pages.scss';

class About extends React.Component {
	state = {
		left: '-100vw',
	};
	componentDidMount() {
		setTimeout(() => {
			this.setState({ left: 0 });
		}, 500);
	}

	render() {
		return (
			<div ref="about" className="pageWrapper">
				<div ref="content" style={{ position: 'relative', top: '20px', left: this.state.left, transition: 'left 1.5s' }}>
					<BluePanel>
						<div className="bluePanelScroll" style={ScrollStyle}>
							<p style={{ marginBottom: '35px' }}>
								As a Creative Front-end Web Developer, I am dedicated to delivering results by analyzing complex business problems and
								designing and architecting appropriate solutions to meet business needs. I possess solid hands-on development skills,
								collaborative abilities, and the capability to interact with individuals of all levels. Having a creative background along
								with 20 years of front-end development experience, I possess excellent communication skills and can quickly adapt to
								environmental and organizational changes. My quick thinking, precise execution, and ability to provide unique solutions when
								standard procedures fail make me an invaluable team member.
							</p>
							<p>
								I have over 20 years of experience in coding user interfaces. However, what sets me apart is my ability to understand and
								appreciate the end-users and paying customers who will interact with the UI I develop. This understanding plays a crucial
								role in how I write and manage the code, ensuring that the UI is efficient, user-friendly, and meets the target audience's
								needs.
							</p>
						</div>
					</BluePanel>
				</div>
			</div>
		);
	}
}

export default About;
