import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import HeaderButton from './headerButton';

import Logo from './images/HeathBishopLogo.svg';
import Icon from './images/HBIcon.svg';
import CollapseMenu from './collapseMenu.js';
import './header.scss';

class Header extends Component {
	constructor() {
		super();

		this.state = {
			page: 'home',
		};
	}

	componentDidMount() {
		this.updateCanvas();
	}

	updateCanvas() {
		const canvas = this.refs.navBackground;
		const ctx = canvas.getContext('2d');

		const style = ctx.createRadialGradient(600, 800, 50, 515, 800, 700);
		style.addColorStop(0, 'rgba(0, 92, 138,1)');
		style.addColorStop(1, '#00070A');

		const rectW = 1030;
		const rectH = 150;

		const rx = rectW / Math.sqrt(2);
		const ry = rectH / Math.sqrt(2) + 10;

		const scaleX = 1;
		const scaleY = ry / rx;

		const invScaleX = 1;
		const invScaleY = rx / ry;

		ctx.setTransform(scaleX, 0, 0, scaleY, 0, 0);
		ctx.fillStyle = style;
		ctx.fillRect(0, 0, rectW * invScaleX, rectH * invScaleY);
	}

	render() {
		return (
			<div className="header">
				<div className="headerButtonContainer" style={{ paddingLeft: '1%' }}>
					<NavLink id="home" to={'/'} className="navLink" exact>
						<>
							<img id="HBLogo" src={Logo} alt="Logo" />
							<img id="HBIcon" src={Icon} alt="Logo" />
						</>
					</NavLink>
				</div>
				<div className="headerNavigation">
					<div className="headerButtonsRow">
						<HeaderButton id="about" link={'/about'} title="ABOUT" maxw="130" minw="75" />
						<HeaderButton id="testimonials" link="/testimonials" title="TESTIMONIALS" maxw="220" minw="170" />
						<HeaderButton id="resume" link="/resume" title="RESUME" maxw="150" minw="95" />
						<HeaderButton id="portfolio" link="/portfolio" title="PORTFOLIO" maxw="175" minw="130" />
						<HeaderButton id="contact" link="/contact" title="CONTACT" maxw="130" minw="110" />
					</div>
					<CollapseMenu />
				</div>
				<canvas
					ref="navBackground"
					width="1030"
					height="96"
					style={{ width: '100%', height: '96px', position: 'fixed', left: '0px', top: '0px' }}
				/>
			</div>
		);
	}
}

export default Header;
