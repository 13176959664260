import React from 'react';
import PropTypes from 'prop-types';

class Experience extends React.Component {
	getDetails(detail) {
		if(typeof(detail.details) !== 'string') {
			return <div>
				<span>{this.generateBulletList(detail.details)}</span>
				<span>{this.getUsedText(detail.used)}</span>
			</div>
		} else if(typeof(detail.details) === 'string') {
			return <div>{this.getClients(detail.clients)}</div>
		}

	}

	generateBulletList(details) {
		return <ul style={{fontSize:'.9em', margin:'0'}}>{
			details.map((d, key) => {
				return <li key={key}>{d}</li>
			})
		}</ul>
	}

	getUsedText(detail) {
		return <span style={{fontSize:'.8em'}}>Used: {detail}</span>
	}

	getClients(detail) {
		return <div style={{fontSize:'.9em'}}>
			{
				detail.map((d, key) => {
					return<ul key={key} style={{margin:'0 0 15px 0'}}>
						<li><span style={{textDecoration:'underline'}}>{d.company}</span> &nbsp;-&nbsp; {d.description}</li>
						<div>{this.generateBulletList(d.details)}</div>
						<div>{this.getUsedText(d.used)}</div>
					</ul>
				})
			}
		</div>
	}

	findInCategories(){
		for(let i = 0; i < this.props.categories.length; i++){
			if(this.props.categories[i].section === this.props.categoryShown){
				return this.props.categories[i];
			}
		}
	}

	render() {
		let categoryDetails = this.findInCategories().details;
		let historyKeys = Object.keys(categoryDetails);
		return (
			<div>
				{
					historyKeys.map((key) => {
						const detail = categoryDetails[key];
						return(
							<div key={key} style={{marginBottom:'30px'}}>
								<div key={detail.company} style={{display:'flex', flexWrap:'wrap'}}>
									<div style={{flex:'2 1'}}>
										<span className='sectionHeading'>{detail.company}<span style={{color:'white'}}>,</span>&nbsp;&nbsp;</span>
										<span style={{display:'inline-block'}}>{detail.location}</span>
									</div>
									<div style={{flex:'1 1', textAlign:'right', marginRight:'10px'}}>{detail.date}</div>
								</div>
								<div style={{marginBottom:'15px'}}>
									<span style={{fontStyle:'italic', fontWeight:'bold'}}>{detail.position}</span>
									<span style={{fontSize:'.9em'}}>&nbsp;-&nbsp;{detail.description}</span>
								</div>
								<div>{this.getDetails(detail)}</div>
							</div>
						)
					})
				}
			</div>
		)


	}
}

Experience.propTypes = {
	categories: PropTypes.array,
	categoryShown: PropTypes.string
};

export default Experience;