import React from 'react';
import PropTypes from 'prop-types';

class Education extends React.Component {
	static propTypes={
		categories: PropTypes.array,
		categoryShown: PropTypes.string
	};

	findInCategories(){
		for(let i = 0; i < this.props.categories.length; i++){
			if(this.props.categories[i].section === this.props.categoryShown){
				return this.props.categories[i];
			}
		}
	}

	render() {
		let categoryDetails = this.findInCategories();

		let detailKeys = Object.keys(categoryDetails.details);

		return (
			<div style={{letterSpacing:'1px'}}>
				{
					detailKeys.map((key) => {
						return(
							<div key={key} style={{display:'flex', flexWrap:'wrap', marginBottom:'15px'}}>
								<div className='sectionHeading' style={{verticalAlign:'text-top', flex:'.75 1', marginRight:'10px', minWidth: '268px'}}>{key}:</div>
								<div style={{verticalAlign:'text-top', minWidth:'150px', flex:'1.75 1'}}>
									<div key={key} style={{display:'flex', flexWrap:'wrap'}}>
										<div style={{flex:'1 1'}}> {categoryDetails.details[key].name}</div>
										<div style={{flex:'1 1'}}>- {categoryDetails.details[key].location}</div>
									</div>
								</div>
							</div>
						)
					})
				}
			</div>
		)
	}
}

export default Education;
