import React from 'react';
import { HashRouter } from 'react-router-dom';

import Header from './components/header/header';
import Main from './components/body/main';
import './App.css';

const App = () => {
	return (
		<HashRouter basename="/">
			<div>
				<Header />
				<Main />
			</div>
		</HashRouter>
	);
};

export default App;
