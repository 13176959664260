import React from 'react';
import './home.css';

class Home extends React.Component {
	state = {
		opacity1: 0,
		opacity2: 0,
		opacity3: 0,
	};

	componentDidMount() {
		setTimeout(() => this.setState({ opacity1: 100 }), 250);
		setTimeout(() => this.setState({ opacity2: 100 }), 1250);
		setTimeout(() => this.setState({ opacity3: 100 }), 2250);
	}

	render() {
		return (
			<div style={{ height: 'calc(100vh - 96px)' }}>
				<div className="homeTextStyle">
					<p className="titleStyle" style={{ opacity: this.state.opacity1 }}>
						<span className="boldText">BROAD</span>
						<span className="titleText">Technical</span>
						<span className="titleText"> Skills</span>
					</p>
					<p className="titleStyle" style={{ opacity: this.state.opacity2 }}>
						<span className="boldText">LIMITLESS</span>
						<span className="titleText">Creativity</span>
					</p>
					<p className="titleStyle" style={{ opacity: this.state.opacity3 }}>
						<span className="boldText">VAST</span>
						<span className="titleText">Imagination</span>
					</p>
				</div>
			</div>
		);
	}
}

export default Home;
