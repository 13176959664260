import React from 'react';
import { NavLink } from 'react-router-dom';

class CollapseMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			menuOpen: false,
		};
		this.opeCloseMenuToggle = this.opeCloseMenuToggle.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', () => {
			if (window.innerWidth > 910) {
				this.setState({ menuOpen: false });
			}
		});
	}

	opeCloseMenuToggle() {
		let flipMenuOpen = this.state.menuOpen === false;
		this.setState({ menuOpen: flipMenuOpen });
	}

	render() {
		return (
			<div id="dropDownMenu">
				<div id="menuBars" onClick={this.opeCloseMenuToggle}>
					<i className="fas fa-bars" />
				</div>
				<div id="dropDownContainer" style={{ height: this.state.menuOpen ? '215px' : 0 }}>
					<div id="dropDown">
						<div className="linkContainer">
							<NavLink
								id="menu-about"
								className="menuBarNavigation"
								activeClassName="menuBarNavigationActive"
								to="/about"
								exact
								onClick={this.opeCloseMenuToggle}
							>
								About
							</NavLink>
						</div>
						<div className="linkContainer">
							<NavLink
								id="menu-testimonials"
								className="menuBarNavigation"
								activeClassName="menuBarNavigationActive"
								to="/testimonials"
								exact
								onClick={this.opeCloseMenuToggle}
							>
								Testimonials
							</NavLink>
						</div>
						<div className="linkContainer">
							<NavLink
								id="menu-resume"
								className="menuBarNavigation"
								activeClassName="menuBarNavigationActive"
								to="/resume"
								exact
								onClick={this.opeCloseMenuToggle}
							>
								Resume
							</NavLink>
						</div>
						<div className="linkContainer">
							<NavLink
								id="menu-portfolio"
								className="menuBarNavigation"
								activeClassName="menuBarNavigationActive"
								to="/portfolio"
								exact
								onClick={this.opeCloseMenuToggle}
							>
								Portfolio
							</NavLink>
						</div>
						<div className="linkContainer">
							<NavLink
								id="menu-contact"
								className="menuBarNavigation"
								activeClassName="menuBarNavigationActive"
								to="/contact"
								exact
								onClick={this.opeCloseMenuToggle}
							>
								Contact
							</NavLink>
						</div>
					</div>
					<div
						style={{
							display: this.state.menuOpen ? 'block' : 'none',
							position: 'fixed',
							top: '0',
							right: '0',
							bottom: '0',
							left: '0',
							zIndex: '2',
						}}
						onClick={this.opeCloseMenuToggle}
					/>
				</div>
			</div>
		);
	}
}

export default CollapseMenu;
