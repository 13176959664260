import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './main.css';

import Background from './background/background';
import Home from './pages/home/home';
import About from './pages/about';
import Testimonials from './pages/testimonials';
import Resume from './pages/resume/resume';
import Portfolio from './pages/portfolio/portfolio';
import Contact from './pages/contact';
import PageNotFound from './pages/pageNotFound';

const Body = () => {
	return (
		<div id="main" style={{ height: 'calc(100vh - 96px)', width: '100%' }}>
			<div id="pageContainer" style={{ height: 'calc(98vh - 96px)' }}>
				<TransitionGroup>
					<CSSTransition key={window.location} classNames="fade" timeout={500}>
						<Switch>
							<Route path="/" component={Home} exact />
							<Route path="/about" component={About} exact />
							<Route path="/testimonials" component={Testimonials} exact />
							<Route path="/resume" component={Resume} exact />
							<Route path="/portfolio" component={Portfolio} exact />
							<Route path="/contact" component={Contact} exact />
							<Route component={PageNotFound} />
						</Switch>
					</CSSTransition>
				</TransitionGroup>
			</div>
			<Background />
		</div>
	);
};

export default Body;
