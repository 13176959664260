import PropTypes from 'prop-types';
import React from 'react';
import './bluePanel.css'

class BluePanel extends React.Component {
	static propTypes = {
		panelStyle: PropTypes.object
	};

	render(){
		return (
			<div id='bluePanel' className='bluePanel bluePanelScroll' style={this.props.panelStyle}>{this.props.children}</div>
		);
	}

};

export default BluePanel;