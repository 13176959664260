import React from 'react';
import Data from './data/testimonials.json';
import BluePanel from './components/bluePanel';
import './pages.scss';
import ScrollStyle from './components/scrollStyle';

class Testimonials extends React.Component {
	state = {
		testimonials: [],
		testimonialShown: null,
		left: '100vw',
	};

	componentDidMount() {
		let arr = [];
		let testimonials = Data.testimonials;

		Object.keys(testimonials).forEach(function (key) {
			arr.push(testimonials[key]);
		});
		// this.setState({testimonials: arr, testimonialShown: arr[0]});
		setTimeout(() => {
			this.setState({ left: 0, testimonials: arr, testimonialShown: arr[0] });
		}, 500);
	}

	getTestimonialNames() {
		if (this.state.testimonials) {
			return (
				<div>
					{this.state.testimonials.map((person, index) => {
						return (
							<p
								onClick={() => {
									this.nameClick(person);
								}}
								className={person.name === this.state.testimonialShown.name ? 'selectedSection' : 'sections'}
								key={index}
							>
								{person.name}
								<span style={{ display: 'block', lineHeight: '20px' }}>-{person.company}</span>
							</p>
						);
					})}
				</div>
			);
		} else {
			return <div />;
		}
	}

	nameClick(person) {
		this.setState({ testimonialShown: person });
	}

	processQuote() {
		let processed = this.state.testimonialShown.quote.split('<br>');
		return (
			<div>
				{processed.map((s, i) => {
					return <p key={i}>{s}</p>;
				})}
			</div>
		);
	}

	render() {
		return (
			<div className="pageWrapper">
				<div ref="content" style={{ position: 'relative', top: '20px', left: this.state.left, transition: 'left 1.5s' }}>
					<BluePanel>
						<div className="gridColumns">
							<div style={{ width: '95vw' }}>
								<div className="sectionHeading">{this.state.testimonialShown ? this.state.testimonialShown.name : ''}</div>
								<div className="bluePanelScroll" style={Object.assign({}, ScrollStyle, { maxHeight: 'calc(100vh - 200px' })}>
									{this.state.testimonialShown ? this.processQuote() : ''}
								</div>
							</div>
							<div style={{ borderLeft: '1px solid rgb(39, 186, 233)', paddingLeft: '10px', minWidth: '130px', width: '21vw' }}>
								{this.getTestimonialNames()}
							</div>
						</div>
					</BluePanel>
				</div>
			</div>
		);
	}
}

export default Testimonials;
