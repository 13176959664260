import React from 'react';
import BluePanel from './components/bluePanel';
import './pages.scss';

class Contact extends React.Component {
	state = {
		left: '100vw',
	};

	componentDidMount() {
		setTimeout(() => {
			this.setState({ left: 0 });
		}, 500);
	}

	render() {
		const labelStyle = { fontSize: '.9em', fontWeight: 'bold' };
		const boxStyle = { backgroundColor: 'rgba(255, 255, 255, 0.29)', padding: '10px' };
		const pStyle = { width: '25vw', maxWidth: '300px' };
		return (
			<div className="pageWrapper">
				<div ref="content" style={{ position: 'relative', top: '25px', left: this.state.left, transition: 'left 1.5s' }}>
					<BluePanel panelStyle={pStyle}>
						<div style={{ fontSize: '1.25em', lineHeight: '2', borderBottom: '1px solid white' }}>HEATH BISHOP</div>
						<div style={Object.assign({}, { margin: '10px 0' }, boxStyle)}>
							<div style={labelStyle}>PHONE</div>
							<div>314.609.9176</div>
						</div>
						<div style={boxStyle}>
							<div style={labelStyle}>EMAIL</div>
							<a
								href="mailto:hbishop@heathbishop.com"
								rel="noopener noreferrer"
								target="_blank"
								style={{ color: '#FFFFFF', textDecoration: 'none' }}
							>
								<div className="email">hbishop@heathbishop.com</div>
							</a>
						</div>
						<div style={Object.assign({}, { margin: '10px 0' }, boxStyle)}>
							<div style={labelStyle}>LOCATION</div>
							<div>St. Louis, MO, USA</div>
						</div>
					</BluePanel>
				</div>
			</div>
		);
	}
}

export default Contact;
